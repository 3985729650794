import * as React from "react"

import {Link} from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Seo from '../components/Seo'

import { getAndSetLang, isLangEng, isLangIt } from "../utils/language" 
import "../utils/language"


const Discussione = () => {
	const lll=""

    let lang = getAndSetLang("it")
    
    let content
    let title

    if( isLangIt() ){
        title="Proclamazione"
        content =
        <>
            <p>
                La proclamazione è puntata per il 28 ottobre alle ore 11.00 AM.

                Non appena il link sarà disponibile, verrà aggiunto
            </p>
        </>
    }
    if( isLangEng() ){
        title="Graduation"
        content = 
        <>
            <p>
                There isn't much information about the Graduation yet, but the fact that it will be on October the 20<sup>th</sup> 2022.
                As soon as there are news, I will publish them.
            </p>
        </>
    }

	return(
		<Layout pageTitle={title} lang={lang}>
			{content}
			<Link to={lll}>
				{lll}
			</Link>
		</Layout>
	)
}

export const Head = () => <Seo title="Discussione" />


export default Discussione